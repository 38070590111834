<template>
  <div class="container">
    <div class="top_container">
      <a-icon @click="$router.go(-1)" class="back_btn" type="left" />
      <span></span>
    </div>
    <div class="content_container">
      <div class="title">{{ question.title }}</div>
      <div v-html="question.content" class="content"></div>
    </div>
  </div>
</template>
<script>
import request from '@/utils/request';
export default {
  data () {
    return {
      question: {}
    }
  },
  created: function () {
    request({
      url: 'knowledge/public/detail',
      data: { id: this.$route.query.question_id }
    }).then(r => {
      this.question = r.data.data
    })
  },
  methods: {
  }
}
</script>
<style lang="less">
.container{
  padding: 8px;
  padding-top: 58px;
  .content_container{
    background-color: #fff;
    padding: 0 10px;
    width: 100%;
    .title{
      font-size: 20px;
      color: #000;
      line-height: 26px;
      padding: 10px 0;
      border-bottom: 1px solid #f1f1f1;
    }
    .content{
      font-size: 16px;
      word-wrap: break-word;
      padding: 10px 0;
      img{
        max-width: 100% !important;
      }
    }
  }
}

</style>
